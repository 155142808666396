.notes-list {
    dt {
        font-weight: bold;
    }

    dd {
        @apply pb-4 mb-4 border-b text-gray-700;

        &:last-child {
            @apply border-0;
        }
    }
}

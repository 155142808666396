.filters__form-group {
    @apply mr-4 mt-2;
}

.filters__hidden {
    .filters__form-group {
        @apply mr-4 mt-4 p-4 border rounded-md w-full md:w-auto;
    }
}

.table-search__dropdown-button {
    @apply text-lightBlue-600;
}

button.table-search__dropdown-button--active {
    @apply text-white bg-lightBlue-600;
}

.filters__tab {
    &.filters__tab--open {
        @apply bg-lightBlue-600 text-white hover:bg-lightBlue-600 pointer-events-none;
    }
}

.filters {
    .filters__fields-container {
        .form-group {
            display: none;

            &.filters__field-row--open {
                display: block;

                .form-group {
                    display: block;
                }
            }
        }
    }
}
.refreshable-content {
  position: relative;
}

.refreshable__overlay {
  position: absolute;
  inset: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;

  &--active {
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: ease;
    opacity: 1;
    z-index: 1000;
  }

  svg {
    color: #4dabf7;
    max-height: 95%;
  }
}

.refreshable__overlay-bg {
  background-color: white;
  position: absolute;
  inset: 0;
  z-index: 1000;
  opacity: 0.75;
  border-radius: 0;

  &--dark {
    background-color: rgb(44, 46, 51);
  }
}
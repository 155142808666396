@tailwind base;

// Elements
/* purgecss start ignore */
@import 'elements/partials';
/* purgecss end ignore */

// Vendor
@import 'vendor/flatpickr';
@import 'vendor/multiselect';
@import 'vendor/awesome-notifications/style';
@import 'vendor/simple-mde/simplemde.min.css';

@tailwind components;

// Components
@import 'components/partials';

@tailwind utilities;

// Helpers, Utilities, Importants, overrides
/* purgecss start ignore */
@import 'trumps/partials';
/* purgecss end ignore */

@import "variables";

$awn-toast-width: 280px;
$awn-toast-padding: 10px;

@import "animations";
@import "popups";
@import "toasts";

[class^="awn-"] {
  box-sizing: border-box;
}
/* purgecss start ignore */
.multi-select {
    font-family: inherit;
    font-size: 16px;
    height: 1.2em;
    box-sizing: border-box;
    cursor: default;
    position: relative;
    width: 200px;
}

.multi-select select {
    border: 0;
    display: inline-block;
    height: 0;
    padding: 0;
    position: absolute;
    visibility: hidden;
    width: 0;
}

.multi-select div {
    margin: 0;
}

.multi-select .multi-select-display {
    background-color: #fff;
    border: solid 1px #a9a9a9;
    border-radius: 0.1em;
    box-sizing: border-box;
    font-size: 80%;
    height: 100%;
    overflow: hidden;
    padding: 1px 20px 1px 4px;
    position: relative;
    white-space: nowrap;
}

.multi-select .multi-select-display::before {
    background-color: #fff;
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
}

.multi-select .multi-select-display::after {
    border-color: #000 transparent transparent;
    border-style: solid;
    border-width: 0.5em 0.25em;
    content: '';
    height: 0;
    position: absolute;
    right: 0.45em;
    top: 0.4em;
    width: 0;
}

.multi-select .multi-select-options {
    display: none;
}

.multi-select-options-overlay {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    overflow: visible;
    z-index: 1000;
}

.multi-select-options-overlay .multi-select-options {
    background-color: #fff;
    border: solid 1px #a9a9a9;
    box-sizing: border-box;
    font-size: 80%;
    position: absolute;
    min-width: 0;
    margin: 0;
    width: auto;
    overflow: auto;
}

.multi-select-options-overlay .multi-select-options .option {
    margin: 0;
}

.multi-select-options-overlay .multi-select-options .option label {
    box-sizing: border-box;
    display: block;
    padding: 1px 4px 1px 1px;
    white-space: nowrap;
    width: 100%;
}

.multi-select-options-overlay .multi-select-options .option label:hover {
    background-color: #39f;
    color: #fff;
}

// End of vendor styling

.multi-select {
    width: 250px;

    @apply h-9;

    .multi-select-display {
        @apply rounded-md border-gray-300 p-2 pr-6;

        &::before {
            @apply w-6;
        }

        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
            background-position: right 0.25rem center;
            background-repeat: no-repeat;
            background-size: 1.5em 1.5em;

            @apply w-6 top-0 right-0 border-none h-full;
        }
    }
}

.multi-select-options-overlay .multi-select-options {
    @apply rounded-md border-gray-300;

    .option label {
        @apply p-2 mb-0;

        input[type='checkbox'] {
            @apply mr-2;
        }
    }
}

.full-width-multi-select .multi-select {
    @apply w-full;
    min-width: 200px;
}

/* purgecss end ignore */

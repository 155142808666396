// HTMX loading indicator, display none when not there so it doesnt take up space.
.htmx-indicator {
    display: none;
}

.htmx-request {
    .htmx-indicator {
        display: block;
    }

    .htmx-indicator--inline-block {
        display: inline-block;
    }

    .htmx-indicator--inline {
        display: inline;
    }
}

.loading-indicator {
    &:after {
        content: " ";
        display: block;
        width: 90%;
        height: 90%;
        margin: 0;
        border-radius: 50%;
        border: 2px solid currentColor;
        border-color: currentColor transparent currentColor transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


abbr[title].no-underline {
    text-decoration: none;
}

.text-opacity-30--children * {
    --tw-text-opacity: 0.3;
}

.text-opacity-reset {
    --tw-text-opacity: 1;
}

.text-opacity-reset--children * {
    --tw-text-opacity: 1;
}
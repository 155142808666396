.header-actions {
    @apply flex sm:ml-4 items-center w-full sm:w-auto;
}

.header-actions--has-primary {
    @apply justify-end;
}

.header-actions--primary-empty {
    @apply justify-start;

    .header-actions__secondary-menu {
        @apply ml-0 sm:ml-3;

        .dropout__trigger {
            @media (max-width: 640px) {
                @apply btn btn--size-md btn--color-tertiary-outline;
            }
        }
    }

    .header-actions__secondary-menu-icon {
        @apply hidden sm:block;
    }
}

.call-tab__outer {
  @apply absolute top-2 lg:top-0 z-20 overflow-hidden right-0;
}

.call-tab {
  --width: 230px;
  --shortened-width: 50px;
  --translate-length: calc(var(--width) - var(--shortened-width));
  @apply transition rounded-tl-full rounded-bl-full bg-lightBlue-600 flex items-center text-white pl-4 p-2 shadow-lg hover:bg-lightBlue-700;

  width: var(--width);

  @media(min-width: 1024px) {
    transform: translate(var(--translate-length), 0);
  }
}

.call-tab__icon {
  @apply mr-4;
}

.call-tab__text {
  @apply text-xs flex-shrink-0;
}

.call-tab:hover {
  transform: translate(0, 0);

  .call-tab__text {
    @apply underline;
  }
}
/* purgecss start ignore */

.dropout__container {
    @apply origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10;
}

.dropout__item {
    @apply flex items-center w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150;

    &:disabled {
        @apply bg-gray-200 opacity-50 cursor-not-allowed;
    }
}

.dropout__item--danger {
    @apply hover:bg-red-700 hover:text-white;
}

.popper-target .dropout__menu-container {
    display: block !important;
}
/* purgecss end ignore */

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

@keyframes skeleton-loading {
  0% {
    background-color: #ebebeb;
  }
  100% {
    background-color: #f5f5f5;
  }
}